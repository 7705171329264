<template>
    <div class="nav-container">
        <div class="logo">
            <router-link to="/" @click="this.$emit('scroll2Home')">
                <img src="../assets/signature-logo2.png" alt="">
            </router-link>
        </div>
        <div class="links space-evenly">
            <a class="hover-this"
                @click="this.$emit('scroll2About')"
                :class="{active: aboutActive}">
                <span>About</span>
            </a>
            <a class="hover-this"
                @click="this.$emit('scroll2Projects')"
                :class="{active: projectsActive}">
                <span>Projects</span>
            </a>
            <a class="hover-this" 
                @click="this.$emit('scroll2Contact')"
                :class="{active: aboutActive}">
                <span>Contact</span>
            </a>
            <div class="cursor"></div>
        </div>
        <div id="sidemenu">
    <button class="sidemenu__btn" v-on:click="navOpen=!navOpen" v-bind:class="{active:navOpen}">
				<span class="top"></span>
				<span class="mid"></span>
				<span class="bottom"></span>
			</button>
    <transition name="translateX">
      <nav v-show="navOpen">
        <div class="sidemenu__wrapper">
          <ul class="sidemenu__list">
            <li class="sidemenu__item"><a class="hover-this"
                @click="this.$emit('scroll2About'), navOpen = false"
                :class="{active: aboutActive}">
                <span>About</span>
            </a></li>
            <li class="sidemenu__item"><a class="hover-this"
                @click="this.$emit('scroll2Projects'), navOpen = false"
                :class="{active: projectsActive}">
                <span>Projects</span>
            </a></li>
            <li class="sidemenu__item"><a class="hover-this" 
                @click="this.$emit('scroll2Contact'), navOpen = false"
                :class="{active: aboutActive}">
                <span>Contact</span>
            </a></li>
          </ul>
        </div>
      </nav>
    </transition>
  </div>
</div>
</template>

<script>

    export default {
        name: 'navigationComp',
        emits:['scroll2Projects'],
        data(){
            return({
                homeActive: false,
                aboutActive: false,
                projectsActive: false,
                navOpen: false,
            })
        },
        methods:{
            handleLinks(tab){
                if(tab == 'home'){
                    this.$emit('home', true);
                    this.homeActive= !this.homeActive
                    this.aboutActive = false;
                    this.projectsActive = false;
                }
                if(tab == 'about'){
                    this.$emit('about', true);
                    this.aboutActive= !this.aboutActive
                    this.homeActive = false;
                    this.projectsActive = false;
                }
                if(tab == 'projects'){
                    this.$emit('projects', true);
                    this.projectsActive= !this.projectsActive
                    this.homeActive = false;
                    this.aboutActive = false;
                }
            }
        },
        
}
</script>

<style scoped>
.nav-container{
    display: flex;
    height: 10vh;
    min-height: 80px;
    position: sticky;
    align-items: center;
    color: white;
    background-color: rgb(0, 0, 0);
    padding: 0 2vw 0 3vw;
}

span{
  font-weight: 400;
  color: #fff;
  font-size: 36px;
  pointer-events: none;
  transition: transform 0.1s linear;
}


@media (min-width: 845px){
    .hover-this:hover ~ .cursor {
        transform: translate(-50%, -50%) scale(6);
    }
    .cursor {
        pointer-events: none;
        position: fixed;
        padding: 0.35rem;
        background-color: #ebe6e6;
        border-radius: 50%;
        mix-blend-mode: difference;
        transition: transform 0.2s ease;
    }
    .hover-this {
        transition: all 0.3s ease;
    }
}

.active{
    color: rgb(139, 253, 225);
}
.links{
    width: 50%;
    margin: 0 auto;
}
img{
    min-height: 50px;
    height: 5vh;
    border-radius: 30%;
    border: 2px solid black;
}
@media(min-width: 760px){
    .hamburger{
        display: none;
    }
    .sidemenu__btn{
      visibility: hidden;
      display: none;
    }
    
}

img:hover{
    border: none;
}
a, span{
    color: white;
    font-family: 'Open-Sans', sans-serif;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 400;
}
a:hover{
    text-decoration: underline;
    text-underline-offset: .4ch;
    cursor: pointer;
}
.space-evenly{
    display: flex;
    justify-content: space-evenly;
}
.space-around {
    display: flex;
    justify-content: space-around;
}
.space-between{
    justify-content: space-between;
}


#sidemenu nav {
  width: 30%;
  height: 100%;
  background: rgb(8, 8, 8);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
}

#sidemenu .sidemenu__btn {
  display: block;
  width: 50px;
  height: 50px;
  background: rgb(8, 8, 8);
  border: none;
  position: relative;
  z-index: 100;
  appearance: none;
  cursor: pointer;
  outline: none;
}
#sidemenu .sidemenu__btn span {
  display: block;
  width: 20px;
  height: 2px;
  margin: auto;
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.4s ease;
}
#sidemenu .sidemenu__btn span.top {
  transform: translateY(-8px);
}
#sidemenu .sidemenu__btn span.bottom {
  transform: translateY(8px);
}
#sidemenu .sidemenu__btn.active .top {
  transform: rotate(-45deg);
}
#sidemenu .sidemenu__btn.active .mid {
  transform: translateX(-20px) rotate(360deg);
  opacity: 0;
}
#sidemenu .sidemenu__btn.active .bottom {
  transform: rotate(45deg);
}
#sidemenu .sidemenu__wrapper {
  padding-top: 50px;
}
#sidemenu .sidemenu__list {
  padding-top: 50px;
  list-style: none;
  padding: 0;
  margin: 0;
}
#sidemenu .sidemenu__item a {
  text-decoration: none;
  line-height: 1.6em;
  font-size: 1.6em;
  padding: 0.5em;
  display: block;
  color: white;
  transition: 0.4s ease;
}
#sidemenu .sidemenu__item a:hover {
  background: lightgrey;
  color: dimgrey;
}
.translateX-enter {
  transform: translateX(200px);
  opacity: 0;
}
.translateX-enter-active, .translateX-leave-active {
  transform-origin: top left 0;
  transition: 0.2s ease;
}
.translateX-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
@media(max-width: 760px){
    #sidemenu nav{
        width: 40%;
    }
    .links{
      display: none;
      visibility: hidden;
    }
}
</style>