<template>

    <div class="main">
        <div class="left">
            <h1 class="myName">Hi, my name's Alex!</h1>
            <div class="right" id="smallScreen">
                <img src="../assets/HeadShot.jpg" alt="">
            </div>
            <p class="hook">A freelance developer taking <b>you</b> 
                from <i>Lorem Ipsem</i> to dream website.</p>
            <button class="call" @click="this.$emit('scroll2Projects')">
                Check out my work
            </button>
        </div>
        <div class="right" id="largeScreen"  v-motion-pop-visible>
            <img src="../assets/HeadShot.jpg" alt="">
        </div>
    </div>
</template>

<script>

    export default {
        name: 'welcomeComp',
        emits:['scroll2Projects'],
        data(){
            return({
            })
        }
    }
</script>
<style scoped>
.main{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
#largeScreen{
    transition-duration: 500ms;
}
h1,h2, p, button{
    font-family: "Open Sans", sans-serif;
}
h1{
    font-weight: 800;
    font-size: 2.5rem;
}
.left, .right{
    margin: 0 auto;
}
.hook{
    max-width: 35ch;
    font-size: 1.5rem;
    font-weight: 400;
}
img{
    max-width: min(45vw, 45vh);
    size: 50% 50%;
    padding-right: 1.5ch;
    border-radius: 50%;
}
.call{
    border: none;
    font-size: 1.5rem;;
    padding: 1ch 1.5ch;
    border-radius: 15px;
    background-color: rgb(14, 16, 16); /*PLAY WITH */
    color: white;
}
button:hover{
    cursor: pointer;
    opacity: 0.9;
}
@media (min-width: 0px){
    img{
        padding: 3ch 0;
    }
    #smallScreen {
        display: inherit;
        padding-top: 2ch;
        visibility: visible;
    }
    #largeScreen {
        display: none;
        visibility: hidden;
    }
    .hook{
        padding: 2em 0;
    }
}
@media(min-width:700px){
    .myName{
        padding-bottom: 0;
        padding-top: 3em;
        /* or this one */
    }
}
@media (min-width: 992px){
    .main{
        height: 80vh;
    }
    .call{
        margin: 0;
    }
    .hook{
        /* padding causing problems */
        margin: 3ch 1.5ch;
    }
    #smallScreen {
        display: none;
        visibility: hidden;
    }
    #largeScreen {
        display: inherit;
        visibility: visible;
    }
}
</style>